import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import jono from '../images/jono.png';
import chen from '../images/chen.png';
import banner from '../images/banner1.png';


const About = () => {
    return (
        
        
        <div className="w-full flex-col h-screen  mb-auto">
            <section>
                <Header />
            </section>  
           <section>
            <div className="flex w-full items-center justify-center min-h-screen bg-auto bg-no-repeat" style={{ backgroundImage: `url(${banner})` }} >
              
                <div class="bg-white rounded-lg border max-w-2xl mx-auto shadow-lg p-10 mt-24"> 
                    <div>
                        <h1 className="text-4xl font-bold text-center ">About Vinetic</h1>
                        <hr className="mt-6 border-b-1 border-gray-200" />
                        <div className='flex flex-row justify-center p-3'>      
                        <div className='flex flex-col items-center text-center'>
                        <img className="w-5/12 " src={jono} alt="jono.png"/> 
                        <h1>Jonathan Frederick <span className='font-bold'><br/>Founder</span></h1>
                         </div>
                         <div className='flex flex-col items-center text-center'>
                        <img className="w-5/12 mx-auto" src={chen} alt="jono.png"/>
                        <h1>Cheney Antoine <span className='font-bold'><br/>Founder</span></h1>
                        </div>
                        </div>
                        <p className="pt-5"><span className='font-bold'>Vinetic,</span> founded in 2021, was created as a unique way to express art through Motion Graphics. The primary goal of the Vinetic team is to use our skills and talents to bring the imagination and dreams of our customers to life. This will be done through cultivating art and explanatory animation videos.
<br/><br/>After months of deliberation, the team decided to expand our knowledge and experiences to better cater to the various needs of our clients. Additionally, we built an interactive website to showcase our work and a space where we can easily communicate with all our clients. 
<br/><br/>The Vinetic company is led by two efficient and passionate leaders; Cheney Antoine and Jonathan Federick. Together our leaders were inspired to name the company Vinectic, simply from the word “Vine” to us this means we are joining our skills to produce the best graphic material for consumers.</p>
                    </div>
                    <div className="text-center pt-10">
                    <h1 className="text-4xl  font-bold text-center ">Mission</h1>
                    <p>Our mission is to introduce elegance and professionalism while taking a strong grip on business ideas and turning imagination and dreams into a reality.</p>
                    </div>
                </div>
            </div>

            </section>
            <section >
                <Footer/>
            </section>
        </div>
    )
}
export default About;
