import { React, useState, useEffect} from 'react';
import registerbg from '../images/registerbg.png';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate , Link } from 'react-router-dom';
import { auth, /*google*/ } from "../auth/Firebase";
import { useAuth } from "../auth/Auth";

let schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(5),
  });


   

function Register() {

     const { signup, currentUser } = useAuth();
  
     const navigate = useNavigate();
  
    const [loading, setLoading] = useState(false);
  
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(schema),
    });

    /*
    useEffect(() => {
        if (currentUser) {
          navigate("/");
        }
        //eslint-disable-next-line
      }, [currentUser]);
    */
      async function onSubmit(data) {
        const email = data.email;
        const password = data.password;
    
        try {
          setLoading(true);
          await signup(email, password);
          navigate("/login");
        } catch (error) {
          alert(error);
        }
    
        setLoading(false);
      }

     /* async function googleSignUp() {
        auth.signInWithPopup(google).catch((error) => {
          console.log(error);
        });
      }*/

    return (
        <div className="min-h-screen w-full h-screen flex flex-col">
         <div class="grid grid-cols-5 grid-row-3 grid-flow-row h-full">
            <div className="col-span-2  row-span-3" style={{ backgroundImage: `url(${registerbg})` }}>
               1
                </div>
             <div class="col-span-3 row-span-3">
                 <div>
                     <h1 className='font-bold text-4xl text-center my-20'>Your Journey Starts Today</h1>
                
        <div className="container max-w-lg mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full  ">
            <h1 className="mb-8 text-3xl text-center">Register</h1>
            <form className="mt-8 space-y-4" onSubmit={handleSubmit(onSubmit)}>

              <>
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Email
                </label>
              </>
              <>
                <input
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                  type="email"
                    {...register("email")}
                  
                />
              </>
             

              <>
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Password
                </label>
              </>
              <>
                <input
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                     type="password"
                    {...register("password")}
                  
                />
              </>
              
              <button
                disabled={loading}
                className="w-full py-3 text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50 "
                type="submit"
              >
                Register
              </button>
            </form>
         
            <p className="pt-2 pb-2 text-center">Or</p>
            <div>
              <div>
                <button
                  //onClick={googleSignUp}
                  disabled={loading}
                  className="w-full py-3 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 "
                  type="submit"
                >
                  SignUp with Google
                </button>          
              </div>
            </div>
            <p className="text-center text-sm text-grey-dark mt-4 cursor-default">
              Already have an account?{" "}
              <Link to="/login">
                <span className="cursor-pointer text-yellow-600 underline underline-offset-3 ...">
                  Sign In Here
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
                 </div>
             </div>
</div>
        
    )
}
export default Register;
