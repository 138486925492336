import { React, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../auth/Auth";
import { useNavigate , Link } from 'react-router-dom';
import { auth,/*google*/ } from "../auth/Firebase";

let schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(5),
  });

function Login()  {

    const { login, currentUser } = useAuth();

    const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit,formState:{errors} } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  async function onSubmit(data) {
    const email = data.email;
    const password = data.password;

    try {
      setLoading(true);
      const user = await login(email, password);
      if(user){
           navigate("/")
      }
      
      //console.log("logged in")
      
         
     
    } catch (error) {
      alert(error);
      console.log("fail")
    }

    setLoading(false);
  }

 /* async function googleSignIn() {
    auth.signInWithPopup(google).catch((error) => {
      console.log(error);
    });
  }*/

    return (
        <div className="App">
      <div className="bg-grey-lighter min-h-screen flex flex-col bg-gradient-to-r from-gray-800 via-blue-500 to-gray-800 ...">
        <div className="container max-w-lg mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full ">
            <h1 className="mb-8 text-3xl text-center">Login</h1>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <>
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Email
                </label>
              </>
              <>
                <input
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                  type="email"
                  {...register("email")}
                />
                <p>{errors.email?.message}</p>
              </>
            
              <>
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Password
                </label>
              </>
              <>
                <input
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                  type="password"
                  {...register("password")}
                />
                 <p>{errors.password?.message}</p>
                <Link to="/forgot-password">
                  <p className="text-right">
                    <span className="cursor-pointer text-sm text-yellow-600 underline underline-offset-3 ...">
                      Forgot Password?
                    </span>
                  </p>
                </Link>
              </>
             
              <button
                disabled={loading}
                className="w-full py-3 text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:ring-opacity-50 "
                type="submit"
              >
                Login
              </button>
            </form>
            <br />
            <div>
              <div>
                <button
                 // onClick={googleSignIn}
                  disabled={loading}
                  className="w-full py-3 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 "
                  type="submit"
                >
                  SignIn with Google
                </button>
              </div>
            </div>
            <p className="text-center text-sm text-grey-dark mt-4 cursor-default ">
              Don't have an account?{" "}
              <Link to="/register">
                <span className="cursor-pointer text-yellow-600 underline underline-offset-3 ...">
                  Register Here
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    );
}
export default Login
