
import {ReactComponentElement, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./auth/Auth";

// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
//import validation from './Validations/BusinessValidation';
import LandingPage from './Components/LandingPage';
import Illustrations from './Components/Illustrations';
import Motiongraphics from './Components/MotionGraphics';
import Quotation from './Components/Quotation';
import Register from './Components/Register';
import Login from './Components/Login';
//import ResetPassword from './Components/ResetPassword';

//  const schema = yup.object().shape({
//   business_name: yup.string().required("Enter Business Name"),
//   email: yup.string().required().email("Enter valid password"),

// });

import {MasterLoginContext} from "./Helper/Context";
import About from './Components/About';

function App() {
const [validate,setValidate] = useState(false);
window.localStorage.setItem("MasterLogin",JSON.stringify(validate));

  return (
    <AuthProvider>
      <MasterLoginContext.Provider value={{validate,setValidate}}>

    
    <Router>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/illustrations' element={<Illustrations />} />
        <Route path='/motion-graphics' element={<Motiongraphics />} />
        <Route path='/about' element={<About />} />
        <Route path='/quotation' element={<Quotation />} /> 
        {/* <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path="/forgot-password" element={<ResetPassword />} /> */}
      </Routes>
    </Router>
    </MasterLoginContext.Provider>
    </AuthProvider>
  );
}

export default App;
