import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getStorage } from "firebase/storage";
import 'firebase/compat/firestore';
//import {getAuth} from 'firebase/auth'


const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:  process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:  process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId:  process.env.REACT_APP_FIREBASE_ID,
    measurementId:  process.env.REACT_APP_FIREBASE_MEASURMENTID
})

const google = new firebase.auth.GoogleAuthProvider();
const storage = getStorage(app);
const auth = app.auth();
//const auth = getAuth(app);
const firestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export{
    auth, google, storage, firestore,timestamp, firebase as default
}