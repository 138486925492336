import React, { useState } from 'react'
import { firestore,storage, timestamp } from "../auth/Firebase";
import { useForm } from "react-hook-form";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import "firebase/compat/storage";




function UploadImagesModal({ showModal, setShowModal }) {
  const createdAt = timestamp();
  const [image, setImage] = useState("");
  const [error, setError] = useState(null);
  const [file, setFile] = useState("");
  const [imageName, setImageName] = useState("");
 

  const {handleSubmit } = useForm();

  const types = ["image/png", "image/jpeg"];

  function handleImage(e){
   // e.preventDefault();
    let pickedFile;
    if(e.target.files && e.target.files.length>0){
      pickedFile = e.target.files[0];
      setImage(pickedFile);
    }

  }

 async function singleUpload(e){

    //e.preventDefault();
    const storageRef = ref(storage,`images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
   
    uploadTask.on(
      "state_changed",
      (snapshot) =>{
        let progress = ((snapshot.uploadBytesResumable/snapshot.totalBytes)*100);
        console.log(progress)
      },
      (err)=>{
        console.log(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          //setUrl(downloadURL)
          

          firestore
            .collection("thumbnails")
            .add({
              image_name: imageName,
              thumbnail: downloadURL,
              CreatedAt: createdAt,

            })
            .then(() => {
              alert("Uploaded");
            })
            .catch((error) => {
              alert(error);
            });

              setImageName("");
        });
      }
    );

  }
  

  
  

  async function onSubmit(data) {
    setShowModal(false);
    singleUpload();
  }
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">Upload Image</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                      <label className="block text-grey-darker text-sm font-bold mb-2">
                        Image Name
                      </label>
                    </>
                    <>
                      <input
                        className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                        type="text"
                        name="imageName"    
                       value={imageName}
                        onChange={(e)=>setImageName(e.target.value)}   
    
                      />
                    </>
                    <>
                      <label class="block text-grey-darker text-sm font-bold mb-2">
                        Upload Image
                      </label>
                    </>
                    <>
                      <input
                        className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                        type="file"
                        name="thumbnail"
                        onChange={handleImage}
                       
                      />

                    </>
                    {/*  <>
                    <input
                      className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                      name="image"
                      type="file"
                      onChange={fileSelectedHandler}
                    />
    </>*/}
                    <div className="output">
                      {error && <div className="error">{error}</div>}
                    </div>

                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button
                        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                         type="submit"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}
export default UploadImagesModal;
