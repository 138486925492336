import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import phonebg from '../images/phonebg.png'

function Quotation() {
  const form = useRef();
  const [msgSent, setMsgSent]=  useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_gchmo8d', 'template_g84dtts', form.current, 'user_UY23g4Y7WJxm5fFuWfZJv')
      .then((result) => {
        e.target.reset();
          console.log(result.text);
          console.log("message sent");
          setMsgSent(true);
      }, (error) => {
        setMsgSent(false);
          console.log(error.text);
          console.log("error");
      });
  };

  return (
    <div className="w-full flex flex-col h-screen mb-auto">
    <section>
    <Header />
</section>
{msgSent ? (
<div className="flex flex-row justify-center bg-blue-400 text-white">
  Your Message was Sent!
</div>
 ):null}
 
<div className="grid grid-cols-2 gap-2 bg-gray-100">
  <div className="p-4 ">
<div class="w-full md:w-5/6 md:max-w-full mx-auto bg-white drop-shadow-md p-6">
      <form onSubmit={sendEmail} ref={form} class="space-y-8">
        <div>
      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">Your email *</label>
      <input type="email" id="email" name="user_email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
      focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-300 
      dark:placeholder-gray-400 dark:text-gray-700 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required/>
</div>
<div>
      <label for="number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">Tel# <span className="text-gray-500">(optional)</span></label>
      <input type="text" id="number" name="user_number" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
      focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-300 
      dark:placeholder-gray-400 dark:text-gray-700 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="contact number" required/>
</div>
<div>
              <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">Subject *</label>
              <input type="text" id="subject" name="user_subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 
              shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-300 dark:placeholder-gray-400 
              dark:text-gray-700 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let us know how we can help you" required/>
          </div>
          <div class="sm:col-span-2">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">Your message *</label>
              <textarea id="message" name="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:border-gray-300 dark:placeholder-gray-400 dark:text-gray-700 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..." required />
          </div>
          <button type="submit" value="Send" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Send message</button>
      
      </form>
      </div>
</div>
<div className="flex flex-col mx-auto p-6 items-center ">
<h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-700 dark:gray-800">Contact Us</h2>
      <p class="mb-8 lg:mb-10 font-light text-center text-gray-700 dark:text-gray-800 sm:text-xl">Connect with us so we can connect with you!</p>
      <div className="">
            <img className="object-fill" src={phonebg} alt="phonebg" />
          </div>
      </div>
</div>
    <section>
    <Footer />
</section>
</div>
  )
}
export default Quotation;
