import { React, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../auth/Auth";
import { auth, google } from "../auth/Firebase";
import { useNavigate} from 'react-router-dom';
import {MasterLoginContext} from "../Helper/Context"


let schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(5),
});


function MasterLoginModal({ showModal, setShowModal, masterLogin }) {
    const { login, currentUser } = useAuth();
    const navigate = useNavigate();
    const {validate, setValidate} = useContext(MasterLoginContext);
    window.localStorage.setItem("MasterLogin",JSON.stringify(validate));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


   
 

    const { register, handleSubmit,formState:{errors} } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitLogin = async ({ email, password }) => {
         try {
           await auth.signInWithEmailAndPassword(email, password);
           setShowModal(false);
           setLoading(true)
           setValidate(true)
          } catch (error) {
            //alert(error);
          }
        };

    // async function onSubmitLogin(data) {
    //     const email = data.email;
    //     const password = data.password;
       
      
    //     try {
    //         setLoading(true);
           
    //         if(password==="123123")
    //         {

            
    //           setValidate(true)
        
    //             setShowModal(false)
                

    //         }
    //        // await login(email, password);
      
            
    //         //console.log(currentUser)
    //         //navigate("/")
    //     } catch (error) {
    //         alert(error);
    //     }

    //     setLoading(false);
    // }


    async function onSubmit(data) {
        setShowModal(false);
       
    }

    


    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t text-center">
                                    <h3 className="text-3xl text-center font-semibold ">Master Login*</h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form onSubmit={handleSubmit(onSubmitLogin)}>
                                        <>
                                            <label className="block text-grey-darker text-sm font-bold mb-2">
                                                Email
                                            </label>
                                        </>
                                        <>
                                            <input
                                                className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                                                type="email"
                                                {...register("email")}
                                            />
                                            <p>{errors.email?.message}</p>
                                        </>

                                        <>
                                            <label className="block text-grey-darker text-sm font-bold mb-2">
                                                Password
                                            </label>
                                        </>
                                        <>
                                            <input
                                                className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                                                type="password"
                                                {...register("password")}
                                            />
                                            <p>{errors.password?.message}</p>
                                        </>


                     
                                        <div className="output">
                                            {error && <div className="error">{error}</div>}
                                        </div>

                                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                            <button
                                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Login
                                                
                                            </button>
                                          
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
             
        </>
    )
}
export default MasterLoginModal;
