import { React, useState, useContext, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import MasterLogin from './MasterLoginModal';
import { MasterLoginContext } from '../Helper/Context';
import UploadVideoModal from "./UploadVideoModal";
import GetImageModal from "./GetImageModal";
import { firestore } from "../auth/Firebase";
import ReactPaginate from 'react-paginate';

const MotionGraphics = () => {

  const { validate, setValidate } = useContext(MasterLoginContext);
  const ref = firestore.collection("videos");
  const [videos, setVideos] = useState([]);
  const [selectVideo, setselectVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 9;


    const endOffset = itemOffset + itemsPerPage;

    const currentItems = videos.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(videos.length / itemsPerPage);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % videos.length;

        setItemOffset(newOffset);
    };
 

  function getVideos() {
      ref.orderBy('CreatedAt','desc').onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
              items.push({...doc.data(),
              id: doc.id});
                  
             //setImageId(doc.data().id)
          });
          
          setVideos(items);
      });
  }



  useEffect(() => {
      getVideos();
      // eslint-disable-next-line
  }, []);

  const openModal = () => {
      setShowModal((prev) => !prev);
  };

  const openImageModal = () => {
      setShowImageModal((prev) => !prev);
  };

  useEffect(() => {
      window.localStorage.setItem("MasterLogin", JSON.stringify(validate));
  }, { validate })

  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault()
    }

    // attach the event listener to 
    // the document object
    document.addEventListener("contextmenu", handleContextMenu)

    // clean up the event listener when 
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])



  return (
<div className="w-full flex flex-col mb-auto ">
            <section>
                <Header />
            </section>
           <section>
             {validate ?(
                    <div className=" flex md:flex-col p-6 items-center ">
                        <button onClick={openModal} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg 
                    focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Upload+</button>
                        <UploadVideoModal showModal={showModal} setShowModal={setShowModal} />
                    </div>

                ):null}

<>
            <div className=" flex-grow items-center justify-center min-h-screen p-6 mx-auto" >
            <div className="grid grid-cols-2 grid-rows-4 md:grid-cols-3 md:grid-rows-4 gap-2 ">
        {currentItems.map((vid) => {
    

             return (
                
                    <div  className=" w-full row-span-2 h-full"
                        key={vid.id} onClick={openImageModal}
                        >    
                         <video onClick={()=>setselectVideo(vid.video)} className="inset-0 h-full w-full object-cover object-center rounded opacity-90 hover:opacity-100" controls muted controlsList="nodownload">
            <source type="video/mp4" src={vid.video}/>

        </video>

                    </div>
              
            )
        })}


   </div>
   <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                renderOnZeroPageCount={null}
                                containerClassName="flex  justify-center items-center pt-5 text-xl mb-5 gap-1"
                                pageLinkClassName="py-1 px-2 cursor-pointer border hover:text-gray-100 text-blue-800 font-semibold bg-white hover:bg-blue-400"
                                previousLinkClassName="bg-white border hover:bg-blue-400 hover:text-gray-100 font-semibold text-blue-800 rounded py-1 px-2 mr-2"
                                nextLinkClassName="bg-white border hover:bg-blue-400 hover:text-gray-100 font-semibold text-blue-800 py-1 px-2 rounded ml-2"
                                activeLinkClassName="bg-blue-400 border text-gray-100"
                            />
            </div>
            </>
            </section>
            <section >
                <Footer/>
            </section>
        </div>
    )
}
export default MotionGraphics;
