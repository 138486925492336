import React from 'react';
import { useState, useRef, useEffect } from 'react';
import pagebg from '../images/pagerbg.png';
import phonebg from '../images/phonebg.png';
import landbg from '../images/deskman.png';
import squares from '../images/squares.png';
//import emailjs from 'emailjs-com';
import validation from '../Validations/BusinessValidation';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { useAuth } from "../auth/Auth";
import { useNavigate} from 'react-router-dom';
import Slider from '../Components/Slider';



const LandingPage = () => {
 
  const  {currentUser} = useAuth();
  const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const [values, setValues] = useState({
        business_name:"",
        email:"",
      });

      useEffect(() => {
        if (currentUser) {
          navigate("/");
        }
      }, [currentUser]);
    
      const [errors, setErrors] = useState({});
    
      const timer = () => {
        setShow(true)
        const timeId = setTimeout(() => {
          // After 3 seconds set the show value to false
          setShow(false)
          //setVis(false)
        }, 5000)
    
        return () => {
          clearTimeout(timeId)
        }
    
      }

    const handleChnage = (event) =>
    {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }

   const goToQuotation = () =>{
    navigate("/quotation");
   }
    
     /* function emailSubscriptions(e) {
      
        
        setErrors(validation(values));
        if(values.business_name==="" || values.email==="")
        {
        
          setErrors(validation(values));
         
        } else {
         e.preventDefault()
          emailjs.sendForm('service_gchmo8d', 'template_g84dtts', e.target, 'user_UY23g4Y7WJxm5fFuWfZJv')
          .then((result) => {
            console.log(result.text); 
            e.target.reset();
          }, (error) => {
            console.log(error.text);
          });
          timer();
        e.target.reset();
        }
        
       
    
      }*/

    return (
      <div className="flex flex-col  mb-auto ">
      <section>
        <Header/>
      </section>
       
        <section className="flex-grow md:flex-col bg-auto bg-no-repeat" style={{ backgroundImage: `url(${pagebg})` }}>
            <section className="pb-40 ">

        <div className=" text-center md:text-left md:flex max-w-7xl mx-auto mt-24 space-x-4">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-2 md:text-left ">
          <div className="flex flex-wrap justify-center md:justify-start md:text-left md:mr-24 md:pb-72">
            <h1 className="font-bold text-5xl leading-tight text-white pb-5 text-white ">Make your imagination a reality</h1>

            <p className="text-white ">Connect with us so we can visually express your business services.</p>
            <div className="mt-5 ">
              {/* <h1 className="text-white font-bold text-2xl pb-3 text-yellow-400">Be on the waiting list today</h1> */}
            <div className="flex justify-center md:justify-start">
              <button onClick={goToQuotation} type="button" className="text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-lg px-5 py-2.5 text-center r dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 mr-5 ml-2  text-center">Connect Now</button>
              </div>
              {show ? (
                <div className=" mt-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative transform  scale-75 transition delay-300 duration-300" role="alert">
                  <strong className="font-bold animate-spin-slow">Congratulations!</strong>
                  <span className="block sm:inline"> You have been added to the waiting list</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </span>
                </div>
              ) : null}
            </div>
            
            
          </div>
   
          <div className="ml-12">
            <img className="object-fill w-5/6 ml-12 md:w-5/6 md:ml-12 invisible md:visible" src={landbg} alt="phonebg" />
          </div>
          </div>
        </div>
        </section>
        <section>
      <Slider/>
      </section>
     {/*  ######### */}
      <div className="flex flex-col md:flex-col mt-32">
        <div className="grid justify-items-center pb-24">
          <h1 className="text-3xl font-bold text-center">What We Provide</h1>
          <div class="py-16 ">  
    <div class="container mx-auto items-center px-6 text-gray-500 md:px-12 xl:px-0">
        <div class="mx-auto grid gap-6 md:w-3/4  lg:grid-cols-3">
            <div class="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                <div class="mb-12 space-y-4">
                    <h3 class="text-2xl font-bold text-gray-900">Animations</h3>
                    <p class="mb-6">Animated videos to help clients undertsand your product</p>
                </div>
                <img src={squares} class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
            </div>
            <div class="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                <div class="mb-12 space-y-4">
                    <h3 class="text-2xl font-bold text-gray-900">Artwork</h3>
                    <p class="mb-6">Enhance your companany's image </p>

                </div>
                <img src={squares} class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
            </div>
            <div class="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                <div class="mb-12 space-y-4">
                    <h3 class="text-2xl font-bold text-gray-900">Ads</h3>
                    <p class="mb-6">Advertisments for your business</p>
                </div>
                <img src={squares} class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
            </div>
          
        </div>
    </div>
</div>

        </div>
      </div>
      </section>
      <section>
        <Footer/>
      </section>
      </div>
    
    )
}
export default LandingPage;

