import { React, useEffect, useState } from 'react';
import navbg from '../images/navigatebg.png';
import vinetic from '../images/vineticlogo.png';
import search from '../images/search.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../auth/Auth";
import dollar from '../images/dollar.png';
import {NavLink} from "react-router-dom";
import {FiMenu} from 'react-icons/fi'

function Header() {
  const { logout, currentUser } = useAuth();
  const [isDiscover, setIsDiscover] = useState(false);
  let location = useLocation();
  const [open, setOpen] = useState(false);


  /*useEffect(() => {
    if (location.pathname == "/discover") {
      setIsDiscover(true);
    }
    else {
      setIsDiscover(false);
    }
    //eslint-disable-next-line
  }, [location]);
*/

  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      alert(error);
    }
  }

  // function handleSignIn() {
  //   navigate("/login");
  // }

  // function handleRegister() {
  //   navigate("/register");
  // }

  function handleHome() {
    navigate("/");
  }

  // function handleIllustrations() {
  //   navigate("/illustrations");
  // }

  // function handleMotionGraphics() {
  //   navigate("/motion-graphics");
  // }

  // function handleAbout() {
  //   navigate("/about");
  // }

  function handleQuotation() {
    navigate("/quotation");
  }


  return (
    <header className=" font-sans p-5 shadow py-2 bg-zinc-900" /*style={{ backgroundImage: `url(${navbg})` }}*/>
     
    <div className="flex items-center justify-between xl:max-w-7xl xl:mx-auto max-w-full px-[1%] flex-wrap w-full">
      <img onClick={handleHome} src={vinetic} alt="" className="h-12 cursor-pointer ml-10"/>

      <FiMenu onClick={()=>setOpen(!open)} color='white' className="lg:hidden block h-6 w-6 cursor-pointer"/>

     <nav className={`${open ? "block": "hidden"} w-full lg:flex lg:items-center lg:w-auto lg:pt-0 pt-5`}>
        <div className="transition-all ease-in-duration-500 items-center flex flex-col lg:flex lg:flex-row">
            
         
    {/* <button onClick={handleMotionGraphics} className="text-md no-underline text-white border-yellow-400 hover:text-yellow-300 hover:border-yellow-300 ml-2 px-1 font-semibold mr-5">
                Animations
              </button> */}
              <NavLink
to="/motion-graphics"
className={({ isActive }) =>
       isActive ? "text-md no-underline text-yellow-400 border-yellow-400 font-semibold " 
       : "text-md no-underline text-white border-yellow-400 hover:text-yellow-300 hover:border-yellow-300 font-semibold "
      }
>
Animations
</NavLink>
<NavLink
to="/illustrations"
className={({ isActive }) =>
       isActive ? "text-md no-underline text-yellow-400 border-yellow-400 font-semibold lg:px-4 my-6 md:my-0" 
       : "text-md no-underline text-white border-yellow-400 hover:text-yellow-300 hover:border-yellow-300 font-semibold px-4 my-6 md:my-0"
      }
>
Illustrations
</NavLink>
<NavLink
to="/about"
className={({ isActive }) =>
       isActive ? "text-md no-underline text-yellow-400 border-yellow-400 font-semibold" 
       : "text-md no-underline text-white border-yellow-400 hover:text-yellow-300 hover:border-yellow-300 font-semibold"
      }
>
About
</NavLink>

<button onClick={handleQuotation} type="button" className="md:my-0 text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm  text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 px-4 my-6 py-2 mx-4 ">
        {/* <img src={dollar} className="mt-1"/> */}
Connect
</button>
           
              </div>
     </nav>

    

    
    </div>
    
   
    {/* {isDiscover ?(
      <div className="sm:mb-0 self-center flex justify-center mr-32">
<div className="xl:w-96">
<div className="input-group relative flex flex-row items-stretch w-full rounded ">
<input type="search" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-gray-100 focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"/>
<span class="input-group-text flex items-center px-2 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded" id="basic-addon2">
<img className="object-cover h-full w-full" src={search} alt="search" />
    </span>
</div>
</div>
      </div>
    ):null

    }
   

    {currentUser ? (
      <div className="sm:mb-0 self-center pr-5">
        {isDiscover ? (

          <div className="sm:mb-0 self-center pr-5">
            <span className="pr-72">
              <button class="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5" onClick={handleDiscover}>
                Images
              </button>
              <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5">
                Graphics
              </button>
            </span>
            <button className="text-md hover:text-blue-dark text-center font-semibold px-2 py-2 text-white bg-red-600 rounded border border-red-600 hover:bg-red-700"
              onClick={handleLogout}>Sign Out</button>
          </div>
        ) : (

          <div className="sm:mb-0 self-center pr-5">
            <span className="pr-72">
            <button type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 
            focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 
            py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"  onClick={handleDiscover}>Discover</button>
             
              <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5">
                About
              </button>
            </span>
            <button className="text-md hover:text-blue-dark text-center font-semibold px-2 py-2 text-white bg-red-600 rounded border border-red-600 hover:bg-red-700"
              onClick={handleLogout}>Sign Out</button>
          </div>
        )}




      </div>
    ) : (
      < div className="sm:mb-0 self-center pr-5">

        {isDiscover ? (
          < div className="sm:mb-0 self-center pr-5">
            <span className="pr-60">
              <button class="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5" onClick={handleDiscover}>
                Images
              </button>
              <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5">
                Graphics
              </button>
            </span>

            <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5" onClick={handleSignIn}>Login</button>


            <button className="text-md hover:text-blue-dark text-center font-semibold px-2 py-2 text-white bg-red-600 rounded border border-red-600 hover:bg-red-700" onClick={handleRegister}>Sign up</button>

          </div>

        ) : (
          < div className="sm:mb-0 self-center pr-5">
            <span className="pr-56">
            <button type="button" class="focus:outline-none text-white bg-red-600 hover:bg-red-500 
            focus:ring-4 focus:ring-red-600 font-medium rounded-lg text-sm px-5 
            py-2.5 mr-2 mb-2 dark:focus:ring-red-900"  onClick={handleDiscover}>Discover</button>
              <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5">
                About
              </button>
            </span>

            <button className="text-md no-underline text-white hover:text-blue-dark ml-2 px-1 font-semibold mr-5" onClick={handleSignIn}>Login</button>


            <button className="text-md hover:text-blue-dark text-center font-semibold px-2 py-2 text-white bg-red-600 rounded border border-red-600 hover:bg-red-700" onClick={handleRegister}>Sign up</button>

          </div>
        )}
      </div>



    )
    } */}


  </header >
  )
}

export default Header;
