import {React, useState, useContext} from 'react';
import masterLogin from '../images/loginicon.png';
import facebook from '../images/facebook.png';
import MasterLoginModal from "../Components/MasterLoginModal";
import {FiMenu} from 'react-icons/fi'

function Footer() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal((prev) => !prev);
      };

    return (
        
           
    <footer className="flex flex-wrap items-center md:justify between lg:justify between p-3 bg-gray-900 ">
        
        <div className="container flex flex-col">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 items-center p-4">
        <div className="flex flex-col mx-auto w-3/6 text-center md:items-start items-center md:text-left">
            <p className='text-white text-sm pb-2'>Bridgetown, Barbados</p>
            <button onClick={openModal} className="text-white text-sm flex flex-row">        
              <span>Master Login</span>  
                <img className=" w-6 h-6 pl-1" src={masterLogin} alt="MasterLogin" />
            </button>
            <MasterLoginModal showModal={showModal} setShowModal={setShowModal}/>
            
        </div>

        <div className="flex flex-col mx-auto w-3/6 items-end text-center">
            <a target="_blank" href="https://www.instagram.com/vinetic246/" className="pb-2 cursor-pointer text-white my-auto flex flex-row ">Follow us on Instagram<span className="pl-1"><svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-white " width="24" height="24" viewBox="0 0 24 24"><path d="M21.231 0h-18.462c-1.529 0-2.769 1.24-2.769 2.769v18.46c0 1.531 1.24 2.771 2.769 2.771h18.463c1.529 0 2.768-1.24 2.768-2.771v-18.46c0-1.529-1.239-2.769-2.769-2.769zm-9.231 7.385c2.549 0 4.616 2.065 4.616 4.615 0 2.549-2.067 4.616-4.616 4.616s-4.615-2.068-4.615-4.616c0-2.55 2.066-4.615 4.615-4.615zm9 12.693c0 .509-.413.922-.924.922h-16.152c-.511 0-.924-.413-.924-.922v-10.078h1.897c-.088.315-.153.64-.2.971-.05.337-.081.679-.081 1.029 0 4.079 3.306 7.385 7.384 7.385s7.384-3.306 7.384-7.385c0-.35-.031-.692-.081-1.028-.047-.331-.112-.656-.2-.971h1.897v10.077zm0-13.98c0 .509-.413.923-.924.923h-2.174c-.511 0-.923-.414-.923-.923v-2.175c0-.51.412-.923.923-.923h2.174c.511 0 .924.413.924.923v2.175z" fillRule="evenodd" clipRule="evenodd"/></svg></span></a>
           {/* <a className='text-white  cursor-pointer text-white my-auto flex flex-row '>Follow us on Facebook<span><img className="w-8 pl-1" src={facebook} alt="fb" /></span></a> */}
            </div>
            

          
           
        </div>
            {/* <ul class="flex mx-auto text-white text-center">
              <li class="p-2 cursor-pointer hover:underline">Terms & Conditions</li>
              <li class="p-2 cursor-pointer hover:underline">Privacy</li>
              <li class="p-2 cursor-pointer hover:underline">Cookies</li>
            </ul> */}

            
<div className=" p-2 bg-gray-900">
  <div>
                <h1 className="text-center text-white font-semibold">Copyright vinetic © 2021</h1>
                </div>
            </div>
            
        </div>
    </footer>

        
    )
}
export default Footer
