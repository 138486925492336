import { type } from "@testing-library/user-event/dist/type";
import React, { useState, useEffect, useRef } from "react";
import { BiSkipPreviousCircle, BiSkipNextCircle } from "react-icons/bi";
import video from "../videos/VID-20201224-WA0006.mp4";
import video1 from "../videos/house.mp4";

const featuredProducts = [
    "/slideImages/girl_edit.png",
    "/slideImages/shoe.png",
    "/src/videos/house.mp4",
    "/src/videos/VID-20201224-WA0006.mp4",
];

const featuredText = [
    "",
    "",
    "",
    "",
];



let count = 0;
let slideInterval;

function Slider(props) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [active, setActive] = useState(true);

    const slideRef = useRef();

    const removeAnimation = () => {
        slideRef.current.classList.remove("fade-anim");
    }

    useEffect(() => {
        slideRef.current.addEventListener('animationend', removeAnimation)
        slideRef.current.addEventListener('mouseenter', pauseSlider)
        slideRef.current.addEventListener('mouseleave', startSlider)
        startSlider();
        return () => {
            pauseSlider();
        }
    }, [])

    const startSlider = () => {
        slideInterval = setInterval(() => {
            handleNextClick();
        }, 5000);
    }

    const pauseSlider = () => {
        clearInterval(slideInterval)
    }

    const handleNextClick = () => {
        count = (count + 1) % featuredProducts.length;
        setCurrentIndex(count);

        slideRef.current.classList.add("fade-anim");
    }



    const handlePreviousClick = () => {
        const productsLength = featuredProducts.length;
        count = (currentIndex + productsLength - 1) % productsLength;
        setCurrentIndex(count);
        slideRef.current.classList.add("fade-anim");
    }


    return (
        <div className="md:w-5/6 sm:3/6 lg:w-6/6 m-auto p-2 drop-shadow-md">
            <div ref={slideRef} className=" select-none relative">
            
                    <div className="flex justify-center aspect-w-16 aspect-h-9 text-center text-white ">
                   
                     {currentIndex === 2  ? (
                        <video className="object-over w-full mix-blend-overlay" autoPlay muted>
                            
                            <source type="video/mp4" src={video1} muted/>

                        </video>
                    ) :(
                    <img className="object-over w-full mix-blend-overlay" src={featuredProducts[currentIndex]} alt="" /> 
                    )} 

                            {currentIndex === 3 &&
                             <video className="object-over w-full mix-blend-overlay" autoPlay muted>
                            
                             <source type="video/mp4" src={video} muted/>
 
                         </video>
                            }

   

                    <h1 className="absolute py-20 bottom-0 inset-x-0">{featuredText[currentIndex]}</h1>
                    <div className="absolute bottom-14 z-30 space-x-3 ">


                        <button onClick={() => { setCurrentIndex(0) }} className={currentIndex == 0 ? "w-10 h-1  bg-white" : "w-10 h-1 bg-gray-400"} />
                        <button onClick={() => { setCurrentIndex(1) }} className={currentIndex == 1 ? "w-10 h-1  bg-white" : "w-10 h-1 bg-gray-400"} />
                        <button onClick={() => { setCurrentIndex(2) }} className={currentIndex == 2 ? "w-10 h-1  bg-white" : "w-10 h-1 bg-gray-400"} />
                        <button onClick={() => { setCurrentIndex(3) }} className={currentIndex == 3 ? "w-10 h-1  bg-white" : "w-10 h-1 bg-gray-400"} />






                    </div>
                </div>
               
              
                <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
                    <button className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition" onClick={handlePreviousClick}><BiSkipPreviousCircle size={30} /></button>
                    <button className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition" onClick={handleNextClick}><BiSkipNextCircle size={30} /></button>
                </div>
            </div>
        </div>
    )
}
export default Slider;
