import { React, useRef, useState, useEffect } from 'react';
import { firestore } from "../auth/Firebase";

function UploadImagesModal({ showImageModal, setShowImageModal, selectImage }) {
  const ref = firestore.collection("thumbnails");
  const [thumbnails, setThumbnails] = useState([]);

  const handleClick = (e) => {

    if (e.target.id === 'container') {
      setShowImageModal(false);
    }


    //


  }


  //   function getThumbnails() {
  //     ref.onSnapshot((querySnapshot) => {
  //         let items = [];
  //         querySnapshot.forEach((doc) => {
  //             items.push({...doc.data(), id: doc.id
  //             });


  //         });

  //          setThumbnails(items);
  //     });
  // }

  // useEffect(() => {
  //   getThumbnails();

  // }, []);


  return (
    <>
      {showImageModal ? (

        <div onClick={handleClick} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div id="container" class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

              <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg scale-150">
                <div class="bg-white sm:p-1">
                  <div class="sm:flex sm:items-start">
                    <div class="bg-white rounded-md text-center">
              
                      <figure className=" w-full  h-full"

                      >
                        <img src={selectImage} alt="imager" className="inset-0 h-full w-full object-cover object-center rounded opacity-90 hover:opacity-100" />
                        {/*<div style={{ backgroundImage: `url(${thumbnails.thumbnail})` }}  className="h-64 inset-0  w-full object-fill object-center rounded opacity-90 hover:opacity-100" /> */}

                      </figure>

                      <div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      ) : null}
    </>
  )
}
export default UploadImagesModal;
