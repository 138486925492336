import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../auth/Firebase";
import { createUserWithEmailAndPassword,sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth"


const AuthContext = createContext({
  
})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    try{ 
      auth.createUserWithEmailAndPassword(email, password)
    }
    catch(error){
      console.log(error)
    }
  }

   function login(email, password) {
   
    return signInWithEmailAndPassword(auth ,email, password)
  }

  function logout() {
    try {
      auth.signOut()
    }
    catch(error){
      console.log(error)
    }
  }
  
  function resetPassword(email) {
    sendPasswordResetEmail(auth, email)
    .then(() => {
        alert("reset email sent to " + email);
    })
    .catch(function (e) {
        console.log(e);
    });
   
  }

  // function updateEmail(email) {
  //   return currentUser.updateEmail(email)
  // }

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password)
  // }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    // updateEmail,
    // updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}